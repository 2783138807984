import React from 'react'
import screensaver from "../../images/background_header/imges_bg_01.jpg";
import IntroductoryText from "../introductory_text/introductory_text";
const AboutUs =()=>{
    return(
        <section className='section_page'>
            <div className='screensaver_header'>
                <img className='images_screensaver blur_img' src={screensaver} alt='мама обіймає доньку'/>
                <IntroductoryText text='Як забронювати пральну машинку, щоб випрати речі'/>
            </div>
        </section>
    )
}
export default AboutUs