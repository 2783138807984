import React, {useEffect} from 'react'
import {title_text_children} from '../../data_store/children/children'
import screensaver from "../../images/background_header/imges_bg_04.jpg";
import './style_children.scss'
import illustration_paper from '../../images/leisure/illustration-paper-windmill-flying-kite-art-craft.jpg'
import joyful_girls from '../../images/leisure/joyful-girls-sliding-down-slide.jpg'
import paper_style from '../../images/leisure/paper-style-puppet-show-composition.jpg'
import {useDispatch, useSelector} from "react-redux";
import {sendChildrenMasterClass} from "../../send_request/send_request";
import {monthDefinitions, parsData} from "../../helpers/parsData/parsData";
import IntroductoryText from "../introductory_text/introductory_text";

const ChildrenLeisure = () => {
    const dispatch = useDispatch()
    const {data_user_children} = useSelector(state => state.childrenMasterClass)
    useEffect(() => {
         dispatch(sendChildrenMasterClass('/children-master-class'))
    },[])

    return (
        <section className='section_page'>
            <div className='screensaver_header '>
                <img className='images_screensaver blur_img' src={screensaver} alt='діти сміються'/>
                {data_user_children.length > 0 ?
                    <div className='list_events'>
                        <h4>майстер клас</h4>
                        <ul>
                            {
                                data_user_children.map((el, index) => {
                                    return (
                                        <li key={index}>
                                            <span className='date_dey'>{parsData(el.date,2)}</span>
                                            <div className='block_date'>
                                                <div className='clock'>
                                                    <span>{el.clock.hour}</span>
                                                    <span>:</span>
                                                    <span>{el.clock.min}</span>
                                                </div>
                                                <span className='date_wi'>{parsData(el.date)}</span>
                                                <span className='date_mo'>{monthDefinitions(el.date)}</span>

                                            </div>
                                            <div className='wrap_info_events'>
                                                <h6>{el.info}</h6>
                                                <p>{el.title}</p>
                                            </div>

                                        </li>
                                    )
                                })

                            }
                        </ul>

                    </div> : <IntroductoryText title ='Дозвілля' text=' для дітей та майстер-класи'/>
                }
            </div>
            <section className='children'>
                <ul className='float_text'>
                    {title_text_children.map((el, index) => {
                        return <li key={index}>
                            {el}
                        </li>
                    })}
                </ul>
                <div className='main_ifo_children_block'>
                    <div className='children_photo'>
                        <img src={illustration_paper} alt='image young'/>
                        <img src={joyful_girls} alt='joyful girls'/>
                        <h3>дозвілля для дітей, майстер-класи</h3>
                    </div>
                    <div className='main_info'>
                        <div className='wrap_info'>
                            <h2> Цікаві та корисні майстер-класи для дітей 3-15 років.</h2>
                            <p> Щодня у нашому центрі проводяться різноманітні майстер-класи.
                                Малювання фарбами, олівцями, виготовлення листівок із паперу,
                                квіти із фоамірану, квілінг, висадження квітів та багато цікавого.
                            </p>
                        </div>

                        <img src={paper_style} alt='paper style'/>
                    </div>
                </div>
            </section>
        </section>
    )
}
export default ChildrenLeisure