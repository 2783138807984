import React  from 'react'
import mom_baby from "../../images/background_header/mom_baby.jpg";
import CalendarTable from "../calendar/calendar";
import checkBusyMachine from "../../helpers/check_busy_machine/check_busy_machine";
import Sidebar from './sidebar/sidebar'
import ModalWindowForm from "./modal_window_form/modal_window_Form";
import LaundryService from "./laundry_service/laundry_service";
import { useSelector} from "react-redux";
import FreeTimeCheck from "./free_time_check/free_time_check";
import ReportList from "./reportList/ReportList";
import {weekDay} from "../../helpers/check_week_day/check_week_day";
import {check_time} from "../../helpers/check_time/check_time";
import InfoAdmin from "./infoAdmin/infoAdmin";


const AppService = () => {
    const {
        action_window,
        report,
        data,
        action_button_nav,
        is_report_active,
        filterDate,
        is_loader,
        is_add_info_admin
    } = useSelector(state => state.booking_service)

//  const dispatch = useDispatch()
// useEffect(()=>{
//     let time;
//     if(is_loader){
//         time = setTimeout(()=>{
//             dispatch(loader(false))
//              // dispatch(messageInfo('сервіс тимчасово недоступний', true))
//         },20000)
//
//     }
//     return () => clearTimeout(time)
// },[is_loader])
    return (
        <div className='screensaver_header app_screensaver_header  '>
            <img className={`images_screensaver background_position `} src={mom_baby}
                 alt='мама з дочкою пере одяг'/>
            <Sidebar/>
            <div className='wrap_timetable'>
                {!checkBusyMachine() ?
                    weekDay(filterDate[0]) ?
                        <p className='title_attention'>На жаль, у цей день послуги прання не працюють</p> :
                        <ul className={`timetable ${action_window || report || is_report_active || action_button_nav ? 'action_filter stop_scroll' : ''}`}>
                            {data.time_notation ? check_time(data.time_notation,data).map((el, index) => {
                                    let counter = 0
                                    el.service.forEach(x => {
                                        if (x.machine === false) {
                                            counter++
                                        }
                                    })
                                    if (counter !== el.service.length) {
                                        return <li className='timetable_item' key={`${index}-timetable_item`}>
                                            <div className='wrap_time'>
                                                <div className='sticky_block'>
                                                    <span>{el.first_time}</span>
                                                    <span>{el.last_time}</span>
                                                    <i>{data.date}</i>
                                                </div>
                                            </div>
                                            <LaundryService service={el.service}
                                                            counter={counter}
                                                            time={el.first_time}
                                                            data_item={data.date}
                                            />
                                        </li>
                                    } else {
                                        return (
                                            <li className='timetable_item' key={`${index}-timetable_item`}>
                                                <div className='wrap_time'>
                                                    <span>{el.first_time}</span>
                                                    <span>{el.last_time}</span>
                                                    <i>{data.date}</i>
                                                </div>
                                                <p className='title_item_attention'>На жаль, на цей час всі пральні машини
                                                    зайняті.</p>
                                            </li>
                                        )
                                    }
                                }) :

                                <li className='text_info_main'>
                                    <p>
                                        Для замовлення пральної машини
                                        скористайтесь календарем
                                    </p>
                                    {is_loader && <div className='wrap_loader'>
                                        <i className='loader_2 loader_style'/>
                                    </div>}
                                </li>
                            }
                        </ul> :
                    <p className='title_attention'>На жаль, в цей день всі пральні машини зайняті</p>
                }
                <CalendarTable/>
                {
                    action_window && <ModalWindowForm/>
                }
                {
                    report && <FreeTimeCheck/>
                }
                {
                    is_report_active && data && <ReportList/>
                }

                {
                    is_add_info_admin && <InfoAdmin/>
                }


            </div>
        </div>
    )
}
export default AppService