import {INITIAL_STATE_PATH_NAME, PATH_NAME} from "../../constants/const-path/const-path";

const PathName = (state=INITIAL_STATE_PATH_NAME,action)=>{
    switch (action.type){
        case PATH_NAME :
            return {
                ...state,check_path:!state.check_path
            }
        default:return state
    }
}
export default PathName