import {
    CHECK_RESPONSE,
    PHONE_USER,
    ACTION_WINDOW,
    REQUEST_REPORT,
    CHECK_REQUEST,
    SCHEDULE_DATA,
    CLEANING_INFO,
    SELECTION_DATE,
    MESSAGE_INFO,
    CLEAR_MESSAGE_INFO,
    CLEAR_DATA,
    CALENDAR_ACTIVE, ACTION_BUTTON_NAV, REPORT_LIST, LOADER, ADD_INFO_ADMIN
} from "../../constants/const-booking_request_service/const-booking_request_service";

export const sendScheduleData=(data)=>{
    return{
        type:SCHEDULE_DATA,
        payload:data
    }
}
export const dataUser= (data)=>{
    return{
        type:PHONE_USER,
        payload:data
    }
}
export const checkResponseBooking =(is_check)=>{
    return{
        type:CHECK_RESPONSE,
        payload:is_check
    }
}
export const actionModalWindow =(action,name,title,url=null,is_action_window,code)=>{
    return{
        type:ACTION_WINDOW,
        payload:{action,name,title,url,is_action_window,code}
    }
}
export const cleaningInfo = ()=>{
    return{
        type:CLEANING_INFO
    }
}
export const clearData=()=>{
    return{
        type:CLEAR_DATA
    }
}
export const requestReport =(i)=>{
    return{
        type:REQUEST_REPORT,
        payload:i
    }
}
export const checkRequest = (check) => {
    return {
        type: CHECK_REQUEST,
        payload: check
    }
}

export const  selectionDate = (date,date_arr)=>{
    return{
        type:SELECTION_DATE,
        payload:{date,date_arr}

    }
}
export const loader =(isLoader)=>{
    return {
        type :LOADER,
        payload:isLoader
    }
}
export const messageInfo =(info,isInfo,phone=null)=>{
    return{
        type:MESSAGE_INFO,
        payload:{info,isInfo,phone}
    }
}
export const clearMessageInfo =()=>{
    return{
        type:CLEAR_MESSAGE_INFO
    }
}
export const activeCalendar=(is_resize=null)=>{
    return{
        type:CALENDAR_ACTIVE,
        payload:is_resize
    }
}
export const actionButtonNav =(is_action=null)=>{
    return{
        type:ACTION_BUTTON_NAV,
        payload:is_action
    }
}
export const windowReport =()=>{
    return{
        type:REPORT_LIST
    }
}
export const addInfoAdmin =(isCheck)=>{
    return{
        type:ADD_INFO_ADMIN,
        payload:isCheck
    }
}