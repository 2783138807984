import {
    ACTION_BUTTON_NAV,
    ACTION_WINDOW, ACTIVE_CALENDAR_L, ADD_INFO_ADMIN, CALENDAR_ACTIVE, CHECK_REQUEST,
    CHECK_RESPONSE, CLEANING_INFO, CLEAR_DATA, CLEAR_MESSAGE_INFO,
    INITIAL_STATE_BOOKING, LOADER, MESSAGE_INFO, REPORT_LIST, REQUEST_REPORT, SCHEDULE_DATA, SELECTION_DATE
} from "../../constants/const-booking_request_service/const-booking_request_service";
import {dataUser} from "../../actions/act-booking_request_service/act-booking_request_service";
import {phone} from "../../data_store/message/message";

const BookingService = (state = INITIAL_STATE_BOOKING, action) => {
    switch (action.type) {
        case CHECK_RESPONSE:
            return {...state, check_send: action.payload}
        case ADD_INFO_ADMIN :
            return {
                ...state , is_add_info_admin : action.payload
            }
        case ACTION_WINDOW:

            return {
                ...state,
                action_window: action.payload.action,
                name_modal_window: action.payload.name,
                url: action.payload.url,
                text_info: action.payload.title,
                is_action_window:action.payload.is_action_window,
                access_code:action.payload.code
            }
        case  CLEANING_INFO:
            return {
                ...state, text_info: ''
            }
        case REQUEST_REPORT:
            return {
                ...state, report: action.payload
            }
        case CHECK_REQUEST:
            return {
                ...state, isSwitch: action.payload
            }
        case SCHEDULE_DATA:

            return {
                ...state, data: action.payload
            }
        case SELECTION_DATE:

            return {
                ...state, date: action.payload.date, filterDate: action.payload.date_arr
            }
        case LOADER :
            return {
                ...state,is_loader:action.payload
            }
        case MESSAGE_INFO:
            return {
                ...state, message_info: action.payload.info, isMessage_info: action.payload.isInfo,phone:action.payload.phone
            }
        case CLEAR_MESSAGE_INFO: {
            return {
                ...state, message_info: '', isMessage_info: false
            }
        }
        case CLEAR_DATA:{
            return {
                ...state ,data:''
            }
        }
        case CALENDAR_ACTIVE:{

            if(action.payload !== null){
                return {
                    ...state ,
                    active_calendar:!state.active_calendar,
                }
            }else {
                return {
                    ...state, active_calendar:action.payload
                }

            }

        }
        case ACTION_BUTTON_NAV:
            if(action.payload !== null){
                return {
                    ...state, action_button_nav:action.payload
                }
            }else {
                return {
                    ...state, action_button_nav:!state.action_button_nav
                }
            }
        case REPORT_LIST:
            return {
                ...state, is_report_active:!state.is_report_active
            }
        default :
            return state
    }
}
export default BookingService