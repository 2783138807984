import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {clearMessageInfo} from "../../../../actions/act-booking_request_service/act-booking_request_service";


const Info = () => {
    const {message_info,isMessage_info,phone} = useSelector(state => state.booking_service)
    const dispatch = useDispatch()
    useEffect(() => {
        let time
        if(isMessage_info){
            time = setTimeout(() => {
                dispatch( clearMessageInfo())
            }, 5000)
        }
        return ()=>clearTimeout(time)
    }, [isMessage_info])

    return (
        <>
            {isMessage_info &&
            <div className='tooltip action_tooltip'>
                <div className='container_tooltip_text'>
                    <div className='tooltip_text'>
                        <h3>увага</h3>
                        <p>{message_info}</p>
                        {phone && <span>{phone}</span>}
                    </div>
                </div>

            </div>
            }
        </>


    )
}
export default Info