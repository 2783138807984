import React from 'react'
import {Navigate, Route, Routes} from "react-router-dom";
import Home from "../home/home";
 import './style_main_content.scss'
import LeisureAdults from "../leisure_adults/leisure_adults";
import ChildrenLeisure from "../children_leisure/children_leisure";
import AboutUs from "../about_us/about_us";
import LaundryService from "../laundry_service/laundry_service";
import ReceptionDoctors from "../reception_doctors/reception_doctors";
import AppService from "../app_service/app_service";


const MainContent=()=>{

    return(
        <>
            <Routes  basename = "/">
                <Route path={'/'} element={ <Home/>}/>
                <Route path ={'/leisure-adults'} element ={<LeisureAdults/>}/>
                <Route path ={'/children-leisure'} element ={<ChildrenLeisure/>}/>
                <Route path ={'/laundry-service'} element ={<LaundryService/>}/>
                <Route path ={'/about-us'} element ={<AboutUs/>}/>
                <Route path ={'/reception-doctors'} element ={<ReceptionDoctors/>}/>
                <Route path ={'/app-Service'} element ={<AppService/>}/>
                {/*<Route path="*" element={<Navigate replace to="/" />} />*/}
            </Routes>
        </>
    )
}
export default MainContent