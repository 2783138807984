import React, {useEffect, useState} from 'react'
import {NavLink} from "react-router-dom"
import './style_nav.scss'
import {useDispatch, useSelector} from "react-redux";
import {setPathName} from "../../actions/act-PathName/act-PathName";
import {actionButtonNav} from "../../actions/act-booking_request_service/act-booking_request_service";
import useWindowSize from "../../hooks/hook_WindowSize";

const NavigationList = () => {
    const dispatch = useDispatch()
    const [path, setPath] = useState(false)
    const {check_path} = useSelector(state => state.path)
    const {action_button_nav} = useSelector(state => state.booking_service)
    const size = useWindowSize();
    useEffect(()=>{
        if(size.width > 1100)dispatch(actionButtonNav(false))
    },[ size])
    useEffect(() => {
        if (window.location.pathname === '/app-Service') {
            setPath(true)
        } else {
            setPath(false)
        }
        dispatch(actionButtonNav(false))

    }, [check_path])

    return (
        <>
            <ul className={`nav_list ${action_button_nav ? 'action_nav_menu':""}  ${path ? 'nav_list_app' : ''}  `}>
                <li>
                    <NavLink className={() => window.location.pathname ==='/' ?
                        'active_button nav_link' : 'nav_link cursor'}
                             to='/'
                             onClick={() => dispatch(setPathName())}>головна</NavLink>
                </li>

                <li>
                    <NavLink  className={({ isActive }) => isActive ?
                        'active_button nav_link' : 'nav_link cursor'}
                             to ='children-leisure'
                             onClick={() => dispatch(setPathName())}>дозвілля для дітей</NavLink>

                </li>
                <li>
                    <NavLink className={({isActive}) => isActive ?
                        'active_button nav_link' : 'nav_link cursor'}
                             to='leisure-adults'
                             onClick={() => dispatch(setPathName())}>дозвілля для дорослих</NavLink>

                </li>
                <li>
                    <NavLink className={({isActive}) => isActive ?
                        'active_button nav_link' : 'nav_link cursor'}
                             to='reception-doctors'
                             onClick={() => dispatch(setPathName())}>прийом лікарів</NavLink>

                </li>
                <li>
                    <NavLink className={({isActive}) => isActive ?
                        'active_button nav_link' : 'nav_link cursor'}
                             to='laundry-service'
                             onClick={() => dispatch(setPathName())}>послуги прання</NavLink>

                </li>

                <li>
                    <NavLink className={({isActive}) => isActive ?
                        'active_button nav_link' : 'nav_link cursor'}
                             to='app-Service'
                             onClick={() => dispatch(setPathName())}>бронювання пральки</NavLink>
                </li>

            </ul>
            <div className='button_nav_mobil cursor ' onClick={()=>dispatch(actionButtonNav())}>
                <span className={`button_nav_mobile_item ${action_button_nav ? 'close_top':''}`}/>
                <span className={`button_nav_mobile_item ${action_button_nav ? 'close_center':''}`}/>
                <span className={`button_nav_mobile_item ${action_button_nav ? 'close_bottom':''}`}/>
            </div>
        </>

    )
}
export default NavigationList