import React, {useState} from "react";
import './style_modal_window.scss'
import {
    actionModalWindow, messageInfo,
    requestReport
} from "../../../actions/act-booking_request_service/act-booking_request_service";
import {useDispatch, useSelector} from "react-redux";
import {CgCloseO} from "react-icons/cg";
import {sendingOrder} from "../../../send_request/send_request";
import {
    message_phone_check_number,
    message_phone_max,
    message_phone_min,
    message_phone_name, phone, phone_info
} from "../../../data_store/message/message";

const ModalWindowForm = () => {
    const dispatch = useDispatch()
    const {
        name_modal_window,
        url,
        text_info,
        is_action_window,
        data,
        isMessage_info,
        access_code
    } = useSelector(state => state.booking_service)
    const [data_obj, setData] = useState({
        last_name: '',
        phone: '',
        code: ''
    })

    const saveDateForm = (e) => {
        const {name, value} = e.target
        if (name === 'phone') {
            if (!/^[0-9]+$/.test(value)) {
                dispatch(messageInfo(message_phone_check_number, true, phone_info))
            }
        }

        setData((state) => ({
            ...state, [name]: value
        }))


    }

    const sendUserData = () => {

        if (!/^[0-9]+$/.test(data_obj.phone)) {
            return
        }

        if (data_obj.last_name === '' || data_obj.last_name.length < 3) {
            return dispatch(messageInfo(message_phone_name, true))
        }
        if (data_obj.phone === '' || data_obj.phone.length < 10) {
            return dispatch(messageInfo(message_phone_min, true))

        } else if (data_obj.phone === '' || data_obj.phone.length > 10) {
            return dispatch(messageInfo(message_phone_max, true))
        }


        let resultData = {
            last_name: data_obj.last_name,
            phone: data_obj.phone,
            time: text_info.today_time,
            machine_number: !is_action_window ? text_info.machine_number.name : ''
        }
        if (access_code === 'CODE') {
            if (data_obj.code !== '14215') return
        }

        dispatch(requestReport(true))
        dispatch(actionModalWindow(false, name_modal_window, text_info))
        dispatch(sendingOrder(url, resultData, data))
    }

    return (
        <div className={`wrap_modal_form ${isMessage_info ? "unset_index" : ''}`}>
            <div className={`modal_window_form  `}>
                <p>{name_modal_window}</p>
                <form>
                    <label>
                        <input type="text"
                               name='last_name'
                               onChange={(e) => saveDateForm(e)}
                               placeholder="Ваше ім'я"/>
                    </label>
                    <label>
                        <input type="text"
                               name='phone'
                               onChange={(e) => saveDateForm(e)}
                               placeholder='Ваш телефон'/>
                    </label>
                    {access_code === 'CODE' &&
                    <label>
                        <input type="text"
                               name='code'
                               onChange={(e) => saveDateForm(e)}
                               placeholder='введіть код доступа'/>
                    </label>
                    }

                </form>
                <button className='save_all_content' onClick={() => {
                    sendUserData()
                }
                }>
                    Надіслати
                </button>
                <i className='cursor' onClick={() => dispatch(actionModalWindow(false))}>
                    <CgCloseO/>
                </i>

            </div>
        </div>
    )
}
export default ModalWindowForm