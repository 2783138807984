import './info_admin_style.scss'
import {CgCloseO} from "react-icons/cg";
import {useDispatch} from "react-redux";
import {addInfoAdmin} from "../../../actions/act-booking_request_service/act-booking_request_service";
const InfoAdmin =()=>{
    const dispatch = useDispatch()
    return(
        <div className='blur_bag'>
            <div className='wrap_info_admin'>
                <div className='info_admin'>
                    <span>номер телефону адміністратора</span>
                    <span>063-690-34-36</span>
                    <span>067-731-00-58</span>
                </div>
                <i className='cursor'
                   onClick={()=> dispatch(addInfoAdmin(false))}
                ><CgCloseO/></i>
            </div>
        </div>

    )
}
export default InfoAdmin