import React from "react";
import {ImCalendar} from 'react-icons/im'
import './style_sidebar.scss'
import Info from "./info/info";
import {RiErrorWarningLine} from "react-icons/all";
import {FaRegQuestionCircle} from 'react-icons/fa'
import {MdOutlineFreeCancellation, MdOutlineLocalLaundryService} from 'react-icons/md'
import {GrClose} from 'react-icons/gr'
import {
    actionModalWindow,
    activeCalendar, windowReport
} from "../../../actions/act-booking_request_service/act-booking_request_service";
import {useDispatch, useSelector} from "react-redux";
import useWindowSize from "../../../hooks/hook_WindowSize";

const Sidebar = () => {
    let action_b = true
    const dispatch = useDispatch()
    const {report, active_calendar, is_report_active, data} = useSelector(state => state.booking_service)
    const resize = useWindowSize()
    window.addEventListener('reset', useWindowSize)
    const openCalendar = () => {
        if (resize.width <= 1000) {
            dispatch(activeCalendar(false))
        } else {
            dispatch(activeCalendar())
        }
    }


    const accessCancellationReserve =()=>{
        !report && dispatch(actionModalWindow(true,
            'будь ласка авторизуйтесь для скасуваня резерву на прання',
            'скасування резерву',
            '/cancellation-laundry', true,'CODE'))
        is_report_active && dispatch(windowReport())
    }
    return (
        <div className='nav_helper'>
            <ul className='sidebar_list'>
                <li className='  nav_link'>
                    <i className='icon_nav_helper'><RiErrorWarningLine/></i>
                    <Info/>
                </li>
                <li className={`cursor button_nav_helper nav_link ${active_calendar && 'action_button_nav'}`}
                    date-text-tooltip='обрати день для прання'
                    onClick={() => {
                        action_b && openCalendar()
                        is_report_active && dispatch(windowReport())
                    }}>
                    <i className={`icon_nav_helper ${active_calendar && ' action_icon_nav'}`}><ImCalendar/></i>
                </li>
                <li className='cursor button_nav_helper nav_link'
                    onClick={() => {


                        !report && dispatch(actionModalWindow(true,
                            'будь ласка' +
                            ' авторизуйтесь для' +
                            ' відновлення номера пральної машини',
                            'номер вашої машинки',
                            'booked-washing-machine-number', true))
                        is_report_active && dispatch(windowReport())
                    }
                    }
                    date-text-tooltip='забув номер машинки'>
                    <i className='icon_nav_helper'><FaRegQuestionCircle/></i>
                </li>
                <li className='cursor button_nav_helper nav_link'
                    onClick={() => {
                        accessCancellationReserve()

                    }
                    }
                    date-text-tooltip='скасувати резерв на прання'>
                    <i className='icon_nav_helper'><MdOutlineFreeCancellation/></i>
                </li>
                {data &&
                <li className='cursor button_nav_helper nav_link'
                    onClick={() => data && dispatch(windowReport())}
                    date-text-tooltip='заброньовані машинки'>
                    {is_report_active ?
                        <i className='icon_nav_helper'><GrClose/></i>
                        :
                        <i className='icon_nav_helper'><MdOutlineLocalLaundryService/></i>
                    }
                </li>
                }

            </ul>
        </div>
    )
}
export default Sidebar