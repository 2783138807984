import {CgSmartHomeWashMachine} from "react-icons/cg";
import {ImPlus} from "react-icons/im";
import {MdOutlineLocalLaundryService} from "react-icons/md";
import {BsCheckLg} from "react-icons/bs";
import {IoClose} from "react-icons/io5";
import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {actionModalWindow} from "../../../actions/act-booking_request_service/act-booking_request_service";
import {dateFormatting} from "../../../helpers/date_formatting/date_formatting";

const ItemComponent = ({time, data, item_machine}) => {
    const dispatch = useDispatch()
    const {isDate,date} = useSelector(state=> state.booking_service)
    const registration = (today_time, today_number, machine_number) => {
        let n = today_number
        if (n === '') {
            n =  isDate? date:  dateFormatting()
        }
        dispatch(actionModalWindow(true, 'Будь ласка, авторизуйтесь',
            {today_time, n, machine_number},
            '/laundry-service/booking-service',
            false
        ))

    }

    return (
        <>
            <div className='icons'>
                <i>{<CgSmartHomeWashMachine/>}</i>
                {item_machine.drying &&
                <i className='add_serv_icon'>
                    <ImPlus className='plus'/>
                    <MdOutlineLocalLaundryService/>
                </i>
                }
            </div>
            <div className='wrap_additional_information_timetable'>
                <span className='date_item'>{data}</span>
                <div className='additional_information_timetable'>
                    <span>номер машинки :</span>
                    <i className='i'>{item_machine.name}</i>
                </div>
                <div className='additional_information_timetable'>
                    <span>сушка:</span>
                    {item_machine.drying ? <i><BsCheckLg/></i> :
                        <i className='red'><IoClose/></i>}
                </div>
                <div className='additional_information_timetable'>
                    <span>пральний порошок:</span>
                    <i><BsCheckLg/></i>
                </div>
                {item_machine.capacity &&
                <div className='additional_information_timetable add_color'>
                    <span> обсяг пральної машинки:</span>
                    <i>{item_machine.capacity} кг</i>
                </div>
                }
                <div className='additional_information_timetable'>
                    <span>час використання:</span>
                    <i>2 години</i>
                </div>
            </div>
            <button onClick={() => registration(time, data, item_machine)}>замовити</button>
        </>
    )
}
export default ItemComponent