import axios from "axios"

export const Axios = axios.create({
    // withCredentials: true,
    baseURL: 'https://app.4you.net.ua',
    responseType: "json",
    headers: {
        'Content-Type': 'application/json'
    },
    credentials: "include",
});