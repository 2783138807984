import {useEffect, useState} from "react";

const useWindowSize =()=>{
    const [window_size,setWindow_size]=useState({
        width:window.innerWidth,
        height:window.innerHeight
    })
    useEffect(()=>{
        function handleResize(){
            setWindow_size({
                width:window.innerWidth,
                height:window.innerHeight
            })
        }
        window.addEventListener("resize", handleResize);
        handleResize()
        return()=>window.removeEventListener('reset',handleResize)
    },[])
    return window_size
}
export default useWindowSize