import React from "react";
import './style,free_tim_loader.scss'
import {BsCheck2Circle,} from 'react-icons/bs'
import {
    checkRequest, cleaningInfo, requestReport
} from "../../../actions/act-booking_request_service/act-booking_request_service";
import {CgCloseO} from "react-icons/cg";
import {useDispatch, useSelector} from "react-redux";
import {bookingRequest} from "../../../send_request/send_request";

const FreeTimeCheck = () => {
    const dispatch = useDispatch()
    const {isSwitch, text_info, date} = useSelector(state => state.booking_service)

    return (
        <div className='free_time_check'>
            {
                isSwitch && <i className='check'><BsCheck2Circle/></i>
            }

            <div className='info_title'>
                <div className='report'>
                    <span>{`номер вашої машинки `}</span>
                    {isSwitch ?
                        <>
                            <span className='answer'>{text_info.machine_number.name}</span>
                            <span>{date}</span>
                            <span>{text_info.today_time}</span>
                        </>
                        :

                        <i className='loader_2'/>

                    }
                </div>
            </div>


            <i className='cursor i' onClick={() => {
                dispatch(checkRequest(false))
                dispatch(requestReport(false))
                dispatch(cleaningInfo())
                dispatch(bookingRequest('/laundry-service/list-day', date))
            }
            }>
                <CgCloseO/>
            </i>
        </div>
    )
}
export default FreeTimeCheck