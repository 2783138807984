export const weekDay = (date) => {
    switch (date) {
        case 'Sun' :
            return true
        case 'Mon' :
            return false
        case 'Tue' :
            return false
        case 'Wed' :
            return false
        case 'Thu':
            return false
        case 'Fri' :
            return false
        case 'Sat' :
            return true
        default :
            return false
    }
}