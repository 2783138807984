import {Axios} from "../http_client/index"

export class HttpClient {
    static async get(endpoint, options = {},) {
        try {
            return await Axios.get(endpoint, options)

        } catch (e) {
            return e.response
        }
    }

    static async post(endpoint, params = {}, options = {},) {

        try {
            return await Axios.post(endpoint, params, options)
        } catch (e) {
            return e.response
        }
    }

    static async delete(endpoint, options={}) {
        try {
            return await Axios.delete(endpoint,options)
        } catch (e) {
            return e.headers
        }
    }
}