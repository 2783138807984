import {combineReducers} from "redux";
import LaundryServiceReducer from "./red-laundry_service/red-laundry_service";
import BookingService from "./red-booking_service/red-booking_service";
import PathName from "./red-path/red-path";
import ChildrenMasterClass from './red-children/red-children'

const rootReducer = combineReducers({
    laundry_service: LaundryServiceReducer,
     booking_service:BookingService,
    path:PathName,
    childrenMasterClass:ChildrenMasterClass
})
export default rootReducer