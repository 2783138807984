export const parsData = (date, numeration = null) => {
    let days = [
        'неділя',
        'понеділок',
        'вівторок',
        'середа',
        'четверг',
        'п’ятниця',
        'субота'
    ];
    const date_pars = date.split('-')
    if (numeration) {
        return date_pars[numeration]
    }

    let new_date_pars = new Date(date)
    let n = new_date_pars.getDay();
    return days[n]
}
export const monthDefinitions = (date) => {
    let mon = [
        'Січень',
        'Лютий',
        'Березень',
        'Квітень',
        'Травень',
        'Червень',
        'Липень',
        'Серпень',
        'Вересень',
        'Жовтень',
        'Листопад',
        'Грудень'
    ]
    let d = new Date(date);
    let month = d.getMonth();
    return mon[month]
}