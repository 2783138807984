import {CHILDREN_MASTER_CLASS, INITIAL_STATE_CHILDREN} from "../../constants/const-children/const-children";

const ChildrenMasterClass = (state = INITIAL_STATE_CHILDREN, action) => {
    switch (action.type) {
        case CHILDREN_MASTER_CLASS:
            return {
                ...state, data_user_children: action.payload
            }
        default:
            return state
    }
}
export default ChildrenMasterClass