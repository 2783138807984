import {
    addInfoAdmin,
    checkRequest,
    clearData, loader,
    messageInfo, requestReport, sendScheduleData
} from "../actions/act-booking_request_service/act-booking_request_service";
import {HttpClient} from "../http_client/http_client";
import {childrenLeisure} from "../actions/act-children/act-children";
import {generationSignatureUser} from "../helpers/generationTocken/generationTocken";

export const bookingRequest = (url, date = null, caseName) => {
    return async dispatch => {
        try {

            const response = await HttpClient.get(url, {
                params: {date},
                headers: {
                    SignatureUser: await generationSignatureUser()
                },
                withCredentials: true
            })

            if (response.status >= 400) {
                return   dispatch(messageInfo('сервіс тимчасово недоступний', true))
            }
            dispatch(sendScheduleData(response.data.userDate))
            dispatch(loader(false))
            // if (response.data.responseAT !== null ) await setAccessToken(response.data.responseAT)
            // if (!response.data.isResponseAT) {
            //     dispatch(sendLogout())
            // }


            // if (caseName === EDIT_ONE_MONTH) {
            //     return dispatch(oneMonths(response.data.data, false))
            // } else {
            //     dispatch(correctionAllMonths(response.data.data, caseName))
            // }

        } catch (error) {
            dispatch(loader(false))
            return  dispatch(messageInfo('сервіс  недоступний', true))
        }

    }
}
export const sendingOrder = (url, data = null, obj_day) => {
    return async dispatch => {
        try {
            const response = await HttpClient.post(url,  {params: {data, obj_day}},{
                headers: {
                    SignatureUser: await generationSignatureUser()
                },
                withCredentials: true
            })
            if(response.data.checkPhoneAccess){
                  dispatch(addInfoAdmin(true))
            }
            if (!response.data.isResponseAT) {
                dispatch(messageInfo(response.data.message, true))
                dispatch(clearData())
                return dispatch(requestReport(false))
            }


            if (response.data.number_machine) {
                dispatch(messageInfo(response.data.message, true))
                dispatch(clearData())
                return dispatch(requestReport(false))
            }
            dispatch(checkRequest(true))
        } catch (error) {
            console.log('error')
        }
    }
}

export const sendChildrenMasterClass = (url, date = null, caseName) => {
    return async dispatch => {
        try{

            const response = await HttpClient.get(url, {
                params: {date},
                headers: {
                    SignatureUser: await generationSignatureUser()
                },
                withCredentials: true
            })
            dispatch(childrenLeisure(response.data.master_class.list_master_class))
        }catch (error){
            dispatch(childrenLeisure([]))
            console.log('error')
        }
    }
}