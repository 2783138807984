import screensaver from "../../images/background_header/happy.jpg";
import doctor from '../../images/background_header/doctor-work1.jpg'
import React from "react";
import './style_doctor.scss'
import IntroductoryText from "../introductory_text/introductory_text";
const ReceptionDoctors =()=>{
    return(
        <section className='section_page'>
            <div className='screensaver_header'>
                <img className='images_screensaver blur_img' src={screensaver} alt='Лікарська консультація та обстеження'/>
                <IntroductoryText title ='Лікарська консультація та обстеження' />
            </div>

            <div className="main_block">
                <div className="block_diagnostics">
                    <h2>Благодійний християнський медичний центр «Милосердя»
                        <span>Заснований у 2003 році </span>
                    </h2>
                    <div className="diagnostics_description">
                        <p>Лікарі медичного центру «Милосердя» - це фахівці вищої категорії. У нашому центрі лікарська допомога надається пацієнтам безкоштовно. Це стало можливим через те, що усі лікарі центру «Милосердя» є християнами. Вони визнають свою працю  у центрі, як служіння Богу і людям.
                        </p>
                        <img className="diagnostics_description_img" src={doctor} alt='doctors'/>
                    </div>
                    <div className="block_method_selection">
                        <h4 className="method_selection_title">
                            Спеціалісти медичного центру
                        </h4>
                        <ul className="method_selection_ul">

                            <li className="method_selection_li active_selection" >
                                <p className="method_selection_p_title"> Кардіолог </p>
                                <div className="detailed_information">


                                </div>
                            </li>
                            <li className="method_selection_li active_selection" >
                                <p className="method_selection_p_title"> УЗД лікар </p>
                                <div className="detailed_information">


                                </div>
                            </li>
                            <li className="method_selection_li active_selection" >
                                <p className="method_selection_p_title"> Окуліст </p>
                                <div className="detailed_information">

                                </div>
                            </li>
                            <li className="method_selection_li active_selection" >
                                <p className="method_selection_p_title"> Невролог </p>
                                <div className="detailed_information">

                                </div>
                            </li>
                            <li className="method_selection_li active_selection" >
                                <p className="method_selection_p_title"> Терапевт-кардіолог </p>
                                <div className="detailed_information">

                                </div>
                            </li>
                        </ul>
                    </div>
                    <div className="diagnostics_enumeration">
                        <h3>Обстеження</h3>
                        <ul className="diagnostics_enumeration_ul">
                            <li className="diagnostics_enumeration_li">
                                <p> Електрокардіограма </p>
                            </li>
                            <li className="diagnostics_enumeration_li">
                                <p> УЗД </p>
                            </li>

                        </ul>
                        <h3>Хто може отримати медичну допомогу?</h3>
                        <p className="diagnostics_enumeration_p">
                            У нашому центрі будь-хто може отримати кваліфіковану лікарську консультацію. Для лікування наших пацієнтів ми використовуємо новітні світові методики. Медикаментозне лікування поєднується із фізіотерапією, лікувальною фізкультурою та навчанням.  Додатково, за призначенням лікаря, можуть виконуватися певні обстеження.
                        </p>
                        <p className="diagnostics_enumeration_attention">
                            Наша адреса: м. Вінниця, вул. Максимовича 5.<i> Телефон для консультації <span>098 508 23 26</span></i>
                        </p>
                        <span className="block_button_direction_price">
                    </span>



                    </div>

                </div>
            </div>
        </section>
    )
}
export default ReceptionDoctors