export const time_notation =[
    {
        first_time:'09:00',
        last_time:'11:00',
        service:[
            {

                name:'b1',
                machine:false,
                drying:true,
                capacity:7
            },
            {
                name:'b2',
                machine:false,
                drying:true,
                capacity:6
            },
            {
                name:'b3',
                machine:false,
                drying:false,
                capacity:7
            },
            {
                name:'b4',
                machine:false,
                drying:false,
                capacity:7
            }

        ]

    },
    {
        first_time:'11:00',
        last_time:'13:00',
        service:[
            {
                name:'b1',
                machine:false,
                drying:true,
                capacity:6
            },
            {
                name:'b2',
                machine:false,
                drying:true,
                capacity:5
            },
            {
                name:'b3',
                machine:false,
                drying:false,
                capacity:5
            },
            {
                name:'b4',
                machine:false,
                drying:false,
                capacity:7
            }
        ]
    },
    {
        first_time:'13:00',
        last_time:'15:00',
        service:[
            {
                name:'b1',
                machine:true,
                drying:true,
                capacity:7
            },
            {
                name:'b2',
                machine:true,
                drying:true,
                capacity:6
            },
            {
                name:'b3',
                machine:false,
                drying:false,
                capacity:6
            },
            {
                name:'b4',
                machine:false,
                drying:false,
                capacity:7
            }
        ]
    },
    {
        first_time:'15:00',
        last_time:'17:00',
        service:[
            {
                name:'b1',
                machine:true,
                drying:true,
                capacity:7
            },
            {
                name:'b2',
                machine:true,
                drying:true,
                capacity:6
            },
            {
                name:'b3',
                machine:true,
                drying:false,
                capacity:6
            },
            {
                name:'b4',
                machine:false,
                drying:false,
                capacity:7
            }
        ]
    }
]

