import React from "react";
import ItemComponent from "../item_component/item_component";

const LaundryService =({data_item,service,counter =null ,time})=>{

    return(
        <ul className='component_icon_list'>
            {service.map((item_machine, i) => {
                if( item_machine.machine ){
                    return (
                        <li className='component_icon_item ' key={`${i}-component_icon_item`}>
                            <ItemComponent item_machine={item_machine} counter={counter} data={data_item} time={time}/>
                        </li>

                    )}

            })}
        </ul>
    )
}
export default LaundryService