import React from 'react'
import img_girl from "../../images/background_header/girl.jpg";
import img_coffee_woman from '../../images/coffee/blissful-short-haired-woman.jpg'
import img_coffee from '../../images/coffee/barista-cafe-making-coffee.jpg'
import './style_leisure_adults.scss'
import {GiCoffeeBeans} from 'react-icons/gi'
import fends_having  from  '../../images/leisure/friends-having.jpg'
import group_creative_friends from  '../../images/leisure/group-creative-friends.jpg'
import happy_mature_friends from '../../images/leisure/happy-mature-friends.jpg'
import IntroductoryText from "../introductory_text/introductory_text";
const LeisureAdults = () => {
    return (
        <section className='section_page'>
            <div className='screensaver_header'>
                <img className='images_screensaver blur_img' src={img_girl} alt='дівчина відпочиває'/>
                <IntroductoryText title ='Цікаве дозвілля' text='для будь-якого віку'/>
            </div>
            <div className='coffee_break'>
                <div className='info_coffee_break'>
                    <h2>Відпочинок для душі у затишному місці</h2>
                </div>
                <div className='main_block_coffee_break'>
                    <div className='main_block_title'>
                        <h3>Готуємо смачну каву </h3>
                        <p>
                            Завітайте до нас на затишний відпочинок.
                            Ми пропонуємо зернову каву та чай різних сортів.
                        </p>

                    </div>
                    <div className='main_block_images'>
                        <i>{<GiCoffeeBeans/>}</i>
                        <img src={img_coffee} alt="дівчина п'є каву"/>
                        <img src={img_coffee_woman} alt='кава'/>
                    </div>
                </div>
                <div className='menu_coffee'>
                    <span className='text_coffee_background'>coffee break</span>
                    <ul>
                        <li>
                            <h5>espresso</h5>
                            <span data_menu='01.'> espresso</span>

                        </li>
                        <li>
                            <h5>Latte</h5>
                            <span data_menu='02.'>Latte</span>

                        </li>
                        <li>
                            <h5>Americano + milk</h5>
                            <span data_menu='03.'>американо + молоко </span>

                        </li>
                        <li>
                            <h5>Cappuccino</h5>
                            <span data_menu='04.'>капучіно </span>

                        </li>
                        <li>
                            <h5> Cacao</h5>
                            <span data_menu='05.'>какао</span>

                        </li>

                    </ul>
                </div>
                <div className='relaxation_section'>
                    <div className='add_ifo_relaxation'>
                        <p>
                            Оберіть свій улюблений напій та насолоджуйтесь спілкуванням із новими друзями
                        </p>
                    </div>
                    <div className='info_relaxation'>
                        <h2>
                            Любите грати в настільні ігри? Ми теж!
                        </h2>
                        <p>
                            В нашому асортименті велика кількість ігр
                            для будь-якого віку. А після гри можна почитати книгу у затишному куточку.
                        </p>
                    </div>
                    <div className='imag_block'>
                        <img src={fends_having} alt='fends having'/>
                        <img src={group_creative_friends} alt='group creative friends'/>
                        <img src={happy_mature_friends} alt='happy mature friends'/>



                    </div>

                </div>
            </div>
        </section>
    )
}
export default LeisureAdults
