
import './App.css';
import NavigationList from "./component/nav/navigation";
import {useSelector} from "react-redux";
import MainContent from "./component/main_content/main_content";

function App() {
  const {action_button_nav} = useSelector(state => state.booking_service)
  return (
      <div className={`App  ${action_button_nav ? 'stop_scroll':""}`}>
        <NavigationList/>
        <MainContent/>
      </div>

  );
}

export default App;
