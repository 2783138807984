import React from "react";

const IntroductoryText = ({title,text,additional_text }) => {
    return(
        <div className='wrap_introductory_text'>
            <h3 className='introductory_text'>{title}</h3>
            <p className='introductory_text'>{text}</p>
            <p  className='introductory_text'>{additional_text}</p>
        </div>

    )
}
export default IntroductoryText