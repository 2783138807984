import {time_notation} from "../../data_store/time_notation/time_notation";
import {NUMBER_WASHING_MACHINES} from "../../data_store/constant/constant";

const checkBusyMachine = () => {
    let counter = 0
    time_notation.forEach(el => {
        return el.service.forEach(i => {
            if (i.machine === false) {
                counter++
            }
        })

    })
    return counter === (time_notation.length * NUMBER_WASHING_MACHINES)

}
export default checkBusyMachine