export const PHONE_USER = 'PHONE_USER',
    CHECK_RESPONSE = 'CHECK_RESPONSE',
    ACTION_WINDOW = 'ACTION_WINDOW',
    REQUEST_REPORT = 'REQUEST_REPORT',
    CHECK_REQUEST = ' CHECK_REQUEST',
    SCHEDULE_DATA = 'SCHEDULE_DATA',
    CLEANING_INFO = 'CLEANING_INFO',
    SELECTION_DATE = ' SELECTION_DATE',
    MESSAGE_INFO = 'MESSAGE_INFO',
    CLEAR_MESSAGE_INFO = 'CLEAR_MESSAGE_INFO',
    CLEAR_DATA = 'CLEAR_DATA',
    CALENDAR_ACTIVE = 'CALENDAR_ACTIVE',
    ACTION_BUTTON_NAV = 'ACTION_BUTTON_NAV',
    REPORT_LIST = 'REPORT_LIST',
    LOADER = 'LOADER',
    ADD_INFO_ADMIN = 'ADD_INFO_ADMIN',
    INITIAL_STATE_BOOKING = {
        check_send: false,
        action_window: false,
        name_modal_window: 'авторизація',
        report: false,
        isSwitch: false,
        url: '',
        text_info: 'ваш номер машинки',
        data: '',
        filterDate: [],
        date: '',
        message_info: '',
        isMessage_info: false,
        is_action_window: false,
        active_calendar: true,
        action_button_nav: false,
        is_report_active: false,
        is_loader: false,
        phone: null,
        is_add_info_admin: false,
            access_code:''

    }