import {useSelector} from "react-redux";
import React from "react";


const ReportList = () => {
    const {data} = useSelector(state => state.booking_service)
    return (
        <ul className='report_window'>
            <li>
                <h3>заброньовані машинки</h3>
            </li>
            {data.time_notation && data.time_notation.map((el, index) => {
                let not_machine_work_counter = 0
                el.service.forEach(x => {
                    if (x.machine === true) {
                        not_machine_work_counter++
                    }
                })
                {
                    if (not_machine_work_counter !== el.service.length) {
                        return <li className='timetable_item' key={`${index}-timetable_item`}>

                            <div className='wrap_time'>
                                <span>{el.first_time}</span>
                                <i>{data.date}</i>

                            </div>
                            <ul className='component_icon_list'>
                                {el.service.map((item_machine, i) => {
                                    if (item_machine.machine === false) {
                                        return (
                                            <li className='component_icon_item cursor' key={`${i}-component_icon_item`}>
                                                <div className='additional_information_timetable'>
                                                    <span>номер машинки :</span>
                                                    <i className='i'>{item_machine.name}</i>
                                                </div>
                                            </li>
                                        )
                                    }
                                })}
                            </ul>
                        </li>
                    } else {
                        <h3>заброньовані машинки</h3>
                    }
                }
            })
            }
        </ul>
    )
}
export default ReportList