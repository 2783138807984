import React from "react";
import screensaver from '../../images/background_header/imges_bg_01.jpg'
import IntroductoryText from "../introductory_text/introductory_text";
const Home = () => {
    return (
        <section className='section_page'>
            <div className='screensaver_header'>
                <img className='images_screensaver individual_setting_images' src={screensaver} alt='мама обіймає доньку'/>
                <IntroductoryText text=''/>
            </div>


        </section>
    )
}
export default Home