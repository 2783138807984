import rootReducer from '../reducers/rootReducer'
import reduxThunk from 'redux-thunk';
import {createStore, applyMiddleware} from "redux";
import { composeWithDevTools } from 'redux-devtools-extension';
// const composeEnhancers =
//     typeof window === 'object' &&
//     window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ?
//         window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({}) : compose;
//
// const loggerMiddleware = store => next => action => {
//     return next(action)
// };
// export const store = createStore(rootReducer, composeEnhancers(applyMiddleware(loggerMiddleware, reduxThunk)));
const loggerMiddleware = store => next => action => {
    return next(action)
};
const composeEnhancers = composeWithDevTools({});
export  const store = createStore(rootReducer,composeEnhancers(applyMiddleware(loggerMiddleware, reduxThunk)))