import React, {useEffect} from 'react'
import Calendar from "react-calendar";
import dayjs from 'dayjs'
import {useDispatch, useSelector} from "react-redux";
import {
    activeCalendar, clearData,
    loader,
    selectionDate
} from "../../actions/act-booking_request_service/act-booking_request_service";
import {bookingRequest} from "../../send_request/send_request";
import useWindowSize from "../../hooks/hook_WindowSize";


const CalendarTable = () => {
    const {active_calendar} = useSelector(state => state.booking_service)
    const dispatch = useDispatch()
    const resize = useWindowSize()
    window.addEventListener('reset', useWindowSize)
    useEffect(() => {
        if (resize.width <= 1000) dispatch(activeCalendar(false))
    }, [])
    const onChanges = (date,date_arr) => {
        dispatch(bookingRequest('/laundry-service/list-day', date))
        dispatch(selectionDate(date,date_arr))
        dispatch(clearData())
        dispatch(loader(true))
        if (resize.width <= 1000) dispatch(activeCalendar(false))
    }

    return (
        <Calendar className={`${active_calendar && 'active_calendar'}`}
                  onClickDay={(date) => {
                      const week = date.toString()
                      let date_arr = week.split(' ')
                      onChanges(dayjs(date).format('DD-MM-YYYY'), date_arr)
                  }}
                  tileClassName={({date}) => {
                      if (dayjs(new Date()).format('DD-MM-YYYY') === dayjs(date).format('DD-MM-YYYY')) {
                          return 'highlight'
                      }
                  }}
                  minDate={new Date()}
                  next2Label={null}
                  prev2Label={null}
                  showNeighboringMonth={false}
                  locale="uk"

        />
    )
}
export default CalendarTable