import React from 'react'
import {VscCalendar} from 'react-icons/vsc'
import {AiOutlineClockCircle} from 'react-icons/ai'
import {MdOutlinePhoneAndroid} from 'react-icons/md'
import {GiWashingMachine} from 'react-icons/gi'
import {MdAddTask} from 'react-icons/md'
import {CgClose} from 'react-icons/cg'
import {GiCoffeeCup} from 'react-icons/gi'
import {NavLink} from "react-router-dom";
import {setPathName} from "../../actions/act-PathName/act-PathName";
import {useDispatch} from "react-redux";
import mom_baby from "../../images/background_header/mom_baby.jpg";
import IntroductoryText from "../introductory_text/introductory_text";

const LaundryService = () => {
    const dispatch = useDispatch()
    return (
        <section className='section_page'>
            <div className='screensaver_header'>
                <img className='images_screensaver blur_img' src={mom_baby} alt='мама з дитиною пере речі'/>
                <IntroductoryText title='працюємо з' text='  15:00 до 19:00 '
                                  additional_text='субота та неділя вихідний'/>
                <div className='button_transition_page transition_application'>
                    <NavLink className='send_page' to='/app-Service'
                             onClick={() => dispatch(setPathName())}>перейти до графіку прання</NavLink>
                </div>
            </div>
            <div className='instruction_user'>
                <h1>Як забронювати пральну машинку, щоб випрати речі</h1>
                <p className='add_text'>Наша пральня самообслуговування працює для вимушених переселенців,
                    які проживають у гуртожитках та інших закладах або винаймають житло без пральної машини.
                    Скористатися нашим сервісом доволі просто. Є два можливі варіанти: у порядку живої черги, або
                    попереднє бронювання.
                    На наш погляд, бронювання має ряд переваг, однією з яких є можливість спланувати свій час.
                </p>
                <div className='section_attention'>
                    <h2>
                        При резервуванні пральної машини просимо Вас звернути увагу на наступне:
                    </h2>
                    <ul>
                        <li>
                            <i><VscCalendar/></i>
                            <span>Машину можна забронювати один раз на три дні</span>
                        </li>
                        <li>
                            <i><AiOutlineClockCircle/></i>
                            <span>На прання відводиться  2 год.</span>
                        </li>
                        <li>
                            <i><GiWashingMachine/></i>
                            <span>Є машини, які ідуть у комплекті з сушкою.</span>
                        </li>
                        <li>
                            <i><CgClose/></i>
                            <span>Ми не сушимо ковдри.</span>
                        </li>
                        <li>
                            <i><GiCoffeeCup/></i>
                            <span>Якщо час прання після 15 години, Ви зможете перечекати в
                            комфортних умовах у центрі підтримки вимушених переселенців.</span>
                        </li>
                    </ul>
                </div>

                <h2>Процес бронювання пральної машини доволі простий і не займе багато часу</h2>

                <ul className='instruction_list'>
                    <li className='instruction_item'>
                        <div className='wrap_images_title'>
                            <i><VscCalendar/></i>
                            <span>1</span>
                        </div>
                        <p className='title_instruction_on'>"Виберіть зручний для Вас день"</p>
                    </li>
                    <li className='instruction_item'>
                        <div className='wrap_images_title'>
                            <i><AiOutlineClockCircle/></i>
                            <span>2</span>
                        </div>
                        <p className='title_instruction_on'>Виберіть вільний час із графіку</p>
                    </li>

                    <li className='instruction_item'>
                        <div className='wrap_images_title'>
                            <i><GiWashingMachine/></i>
                            <span>3</span>
                        </div>
                        <p className='title_instruction_on'> Виберіть пральну машинку</p>
                    </li>
                    <li className='instruction_item'>
                        <div className='wrap_images_title'>
                            <i><MdOutlinePhoneAndroid/></i>
                            <span>4</span>
                        </div>
                        <p className='title_instruction_on'>Введіть номер Вашого телефону</p>
                    </li>
                    <li className='instruction_item'>
                        <div className='wrap_images_title'>
                            <i><MdAddTask/></i>
                            <span>5</span>
                        </div>
                        <p className='title_instruction_on'>Запам'ятайте номер вибраної пральної машини </p>
                    </li>
                    <li className='instruction_item '>
                        <div className='schedule'>
                            <h5> Розклад</h5>
                            <span>пн-пт</span>
                            <span>09:00-19:00</span>
                        </div>
                        <NavLink className='send_page' to='/app-Service' onClick={() => dispatch(setPathName())}>перейти
                            до графіку прання</NavLink>
                    </li>
                </ul>

            </div>
        </section>
    )
}

export default LaundryService